@use './includes/includes' as *;

.page,
.post {
  main {
    width: 90vw;
    max-width: 70ch;

    position: relative;
    z-index: 1;

    img {
      max-width: 100%;
    }
  }
}

.post {
  h1 {
    padding-block-end: var(--spacing-block-half);
    border-block-end: 0.5vh solid var(--accent-color);
  }

  time {
    @include time-stamp();
    margin-block-end: var(--spacing-block-triple);
  }

  .pagination {
    margin-block-start: var(--spacing-block-double);
  }
}

.tags {
  a {
    margin-inline-end: 0.5rem;
  }
}

pre[class*='language-'] {
  width: calc(100vw - (2 * var(--spacing-inline)));
  max-width: 80ch;

  &:has(+ p),
  &:has(+ h2),
  &:has(+ h3),
  &:has(+ ol) {
    display: block;
    margin-block-end: 1.3rem;
  }
}

.all-tags {
  columns: auto 12rem;

  li {
    break-inside: avoid;
  }
}

.blog-posts--all {
  columns: auto 30rem;

  li {
    break-inside: avoid;
  }
}
