@use './includes/includes' as *;

.site-title {
  font-size: 4rem;
  margin-block: var(--spacing-block);
  margin-inline: var(--spacing-inline);

  a {
    text-decoration: none;
    outline-width: 0.5vh;
  }
}

.site-subtitle {
  padding: 0 0 var(--spacing-block-half) var(--spacing-inline);
  font-family: var(--body);
  font-weight: 200;

  line-height: 0.95;

  display: inline-block;
  border-block-end: 0.5vh solid var(--accent-color);
}

.home-nav ul {
  @include fade-in(1s);
  list-style: none;
  font-size: var(--font-size-body-large);
  font-weight: 300;

  li {
    padding-block-end: 0.5vh;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: var(--spacing-block);
    right: var(--spacing-inline);
    text-align: right;
  }
}

.header-backdrop {
  @include fade-in(2s);
  position: absolute;
  top: 9rem;
  right: var(--spacing-inline);
  width: 33vw;

  @media (min-width: 600px) {
    width: 40vw;
    top: 12rem;
  }
}

.home main {
  @include fade-in(2.5s);
  padding-block-start: 20vh;

  @media (min-width: 600px) {
    max-width: 37.5rem;
  }
}

.blog-posts-title {
  font-weight: var(--bold);
  margin-block-end: var(--spacing-block);
}

.blog-posts {
  margin-block-end: var(--spacing-block-double);
}

.pagination {
  display: inline-block;
  border-block-start: 1px solid var(--orange);
  padding-block-start: var(--spacing-block-half);
}
