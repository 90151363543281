@keyframes fade-in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

:root {
  --black: #151515;
  --almost-black: #1e1e1e;
  --grey-dark: #333;
  --grey-medium: #666;
  --grey-light: #ccc;
  --white: #fafafa;
  --orange: #fd8b26;
  --orange-tan: #ce9178;
  --accent: "Source Serif 4", Georgia, serif;
  --system: -apple-system, BlinkMacSystemFont, sans-serif;
  --font-family-root: var(--system);
  --font-size-root: clamp(15px, 2vw + .1em, 20px);
  --font-size-body-large: 1.33rem;
  --bold: 700;
  --line-height: 1.46;
  --spacing-block: clamp(25px, 3vh, 30px);
  --spacing-block-half: calc(var(--spacing-block) / 2);
  --spacing-block-third: calc(var(--spacing-block) / 3);
  --spacing-block-quarter: calc(var(--spacing-block) / 4);
  --spacing-block-double: calc(var(--spacing-block) * 2);
  --spacing-block-triple: calc(var(--spacing-block) * 3);
  --spacing-inline: clamp(20px, 4vw, 80px);
  --background: var(--white);
  --foreground: var(--black);
  --accent-color: var(--orange);
  --code-text: var(--orange-tan);
  --code-background: var(--almost-black);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--black);
    --foreground: var(--white);
    --bold: 800;
  }
}

* {
  margin: 0;
  padding: 0;
}

:root {
  background: var(--background);
  width: 100%;
  color: var(--foreground);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  font-family: var(--font-family-root);
  font-size: var(--font-size-root);
  box-sizing: border-box;
  view-transition-name: root;
  position: relative;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

:root *, :root :before, :root :after {
  box-sizing: inherit;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

::view-transition-old(root) {
  animation: .2s linear forwards fade;
}

::view-transition-new(root) {
  animation: .3s linear reverse fade;
}

@media (prefers-reduced-motion: reduce) {
  ::view-transition-old(root) {
    animation-duration: 1ms;
  }

  ::view-transition-new(root) {
    animation-duration: 1ms;
  }
}

main {
  padding-block: var(--spacing-block);
  padding-inline: var(--spacing-inline);
}

h6, h5, h4, h3, h2, h1 {
  line-height: 1.3;
  font-family: var(--accent);
  margin-block-end: calc(.1em + 1vh);
  font-weight: 900;
}

h1 {
  margin-top: 4vh;
  margin-bottom: 2.5vh;
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 1.8rem;
  font-weight: 450;
}

h4 {
  font-size: 1.6rem;
  font-weight: 400;
}

h5 {
  font-size: 1.4rem;
  font-weight: 350;
}

h6 {
  font-size: 1.2rem;
  font-weight: 300;
}

a {
  color: currentColor;
  text-underline-offset: .15rem;
  outline-offset: .2vw;
  outline: 2px solid #0000;
  text-decoration-line: underline;
  text-decoration-thickness: .1rem;
  transition: outline-color .3s;
}

a:hover, a:focus {
  outline-color: var(--orange);
  border-color: var(--orange);
}

p {
  line-height: var(--line-height);
  margin-block-end: var(--spacing-block-half);
}

p:has( + h2) {
  margin-block-end: var(--spacing-block);
}

ul {
  list-style: disc;
}

ul, ol {
  margin-block-end: var(--spacing-block);
}

li {
  line-height: var(--line-height);
  margin-inline-start: var(--spacing-inline);
  padding-block-end: var(--spacing-block-third);
}

li:last-of-type {
  padding-block-end: 0;
}

li ul, li ol {
  margin-block-end: 0;
  padding-block-start: var(--spacing-block-third);
}

li > p:last-child {
  margin-block-end: 0;
}

p code, li code {
  color: var(--code-text);
  background-color: var(--code-background);
  padding: .1rem .3rem;
  display: inline-block;
}

p > img {
  margin-block-start: 1vh;
}

blockquote {
  border-inline-start: 1px solid var(--foreground);
  margin-block: 2vh;
  margin-inline: 2vw;
  padding: 1vh 2vw;
}

b, strong, abbr {
  font-weight: var(--bold);
}

data {
  font-family: var(--mono);
}

mark {
  background-color: var(--foreground);
  color: var(--background);
}

pre {
  white-space: pre-wrap;
  margin-block-end: var(--spacing-block);
}

table {
  border-bottom: 1px solid var(--foreground);
  margin-bottom: 2rem;
}

thead {
  border-bottom: 1px solid var(--foreground);
  background-color: var(--foreground);
  color: var(--background);
}

tr:nth-child(2n) {
  background-color: var(--grey-light);
}

@media (prefers-color-scheme: dark) {
  tr:nth-child(2n) {
    background-color: var(--grey-dark);
  }
}

td, th {
  padding: 2vh 2vw;
}

hr {
  border-color: var(--accent-color);
}

.hidden {
  display: none;
}

pre[class*="language-"], code[class*="language-"] {
  color: #d4d4d4;
  text-shadow: none;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  direction: ltr;
  font-family: Menlo, Monaco, Consolas, Andale Mono, Ubuntu Mono, Courier New, monospace;
  font-size: 1rem;
  line-height: 1.5;
}

pre[class*="language-"]::selection, code[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #264f78;
}

@media print {
  pre[class*="language-"], code[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  background: var(--code-background);
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"] {
  color: #db4c69;
  background: #1e1e1e;
  border-radius: .3em;
  padding: .1em .3em;
}

.namespace {
  opacity: .7;
}

.token.doctype .token.doctype-tag {
  color: #569cd6;
}

.token.doctype .token.name {
  color: #9cdcfe;
}

.token.comment, .token.prolog {
  color: #6a9955;
}

.token.punctuation, .language-html .language-css .token.punctuation, .language-html .language-javascript .token.punctuation {
  color: #d4d4d4;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.inserted, .token.unit {
  color: #b5cea8;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.deleted {
  color: var(--code-text);
}

.language-css .token.string.url {
  text-decoration: underline;
}

.token.operator, .token.entity {
  color: #d4d4d4;
}

.token.operator.arrow {
  color: #569cd6;
}

.token.atrule {
  color: var(--code-text);
}

.token.atrule .token.rule {
  color: #c586c0;
}

.token.atrule .token.url {
  color: #9cdcfe;
}

.token.atrule .token.url .token.function {
  color: #dcdcaa;
}

.token.atrule .token.url .token.punctuation {
  color: #d4d4d4;
}

.token.keyword {
  color: #569cd6;
}

.token.keyword.module, .token.keyword.control-flow {
  color: #c586c0;
}

.token.function, .token.function .token.maybe-class-name {
  color: #dcdcaa;
}

.token.regex {
  color: #d16969;
}

.token.important {
  color: #569cd6;
}

.token.italic {
  font-style: italic;
}

.token.constant {
  color: #9cdcfe;
}

.token.class-name, .token.maybe-class-name {
  color: #4ec9b0;
}

.token.console, .token.parameter, .token.interpolation {
  color: #9cdcfe;
}

.token.punctuation.interpolation-punctuation, .token.boolean {
  color: #569cd6;
}

.token.property, .token.variable, .token.imports .token.maybe-class-name, .token.exports .token.maybe-class-name {
  color: #9cdcfe;
}

.token.selector, .token.escape {
  color: #d7ba7d;
}

.token.tag {
  color: #569cd6;
}

.token.tag .token.punctuation, .token.cdata {
  color: gray;
}

.token.attr-name {
  color: #9cdcfe;
}

.token.attr-value, .token.attr-value .token.punctuation {
  color: var(--code-text);
}

.token.attr-value .token.punctuation.attr-equals {
  color: #d4d4d4;
}

.token.entity {
  color: #569cd6;
}

.token.namespace {
  color: #4ec9b0;
}

pre[class*="language-javascript"], code[class*="language-javascript"], pre[class*="language-jsx"], code[class*="language-jsx"], pre[class*="language-typescript"], code[class*="language-typescript"], pre[class*="language-tsx"], code[class*="language-tsx"] {
  color: #9cdcfe;
}

pre[class*="language-css"], code[class*="language-css"] {
  color: var(--code-text);
}

pre[class*="language-html"], code[class*="language-html"] {
  color: #d4d4d4;
}

.language-regex .token.anchor {
  color: #dcdcaa;
}

.language-html .token.punctuation {
  color: gray;
}

pre[class*="language-"] > code[class*="language-"] {
  z-index: 1;
  position: relative;
}

.line-highlight.line-highlight {
  z-index: 0;
  background: #f7ebc6;
  box-shadow: inset 5px 0 #f7d87c;
}

.site-title {
  margin-block: var(--spacing-block);
  margin-inline: var(--spacing-inline);
  font-size: 4rem;
}

.site-title a {
  outline-width: .5vh;
  text-decoration: none;
}

.site-subtitle {
  padding: 0 0 var(--spacing-block-half) var(--spacing-inline);
  font-family: var(--body);
  border-block-end: .5vh solid var(--accent-color);
  font-weight: 200;
  line-height: .95;
  display: inline-block;
}

.home-nav ul {
  font-size: var(--font-size-body-large);
  font-weight: 300;
  list-style: none;
}

@media (prefers-reduced-motion: no-preference) {
  .home-nav ul {
    animation-name: fade-in;
    animation-duration: 1s;
  }
}

.home-nav ul li {
  padding-block-end: .5vh;
}

@media (width >= 600px) {
  .home-nav ul {
    top: var(--spacing-block);
    right: var(--spacing-inline);
    text-align: right;
    position: absolute;
  }
}

.header-backdrop {
  top: 9rem;
  right: var(--spacing-inline);
  width: 33vw;
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .header-backdrop {
    animation-name: fade-in;
    animation-duration: 2s;
  }
}

@media (width >= 600px) {
  .header-backdrop {
    width: 40vw;
    top: 12rem;
  }
}

.home main {
  padding-block-start: 20vh;
}

@media (prefers-reduced-motion: no-preference) {
  .home main {
    animation-name: fade-in;
    animation-duration: 2.5s;
  }
}

@media (width >= 600px) {
  .home main {
    max-width: 37.5rem;
  }
}

.blog-posts-title {
  font-weight: var(--bold);
  margin-block-end: var(--spacing-block);
}

.blog-posts {
  margin-block-end: var(--spacing-block-double);
}

.pagination {
  border-block-start: 1px solid var(--orange);
  padding-block-start: var(--spacing-block-half);
  display: inline-block;
}

.about h1 {
  padding: 0 var(--spacing-inline) var(--spacing-block) 0;
  border-block-end: .5vh solid var(--accent-color);
  background-color: var(--background);
  z-index: 1;
  font-size: 4rem;
  display: inline-block;
  position: relative;
}

.about .about-backdrop {
  z-index: 0;
  width: clamp(200px, 50vw, 800px);
  margin: 0;
}

@media (width >= 1000px) {
  .about .about-backdrop {
    width: 34vw;
    position: absolute;
    top: 12.5rem;
    right: 0;
  }
}

@media (width >= 1300px) {
  .about .about-backdrop {
    left: 800px;
    right: auto;
  }
}

.about .content {
  max-width: 600px;
}

.page main, .post main {
  z-index: 1;
  width: 90vw;
  max-width: 70ch;
  position: relative;
}

.page main img, .post main img {
  max-width: 100%;
}

.post h1 {
  border-block-end: .5vh solid var(--accent-color);
  padding-block-end: var(--spacing-block-half);
}

.post time {
  color: var(--grey-medium);
  margin-block-end: var(--spacing-block-quarter);
  margin-block-end: var(--spacing-block-triple);
  font-size: .73rem;
  display: block;
}

@media (prefers-color-scheme: dark) {
  .post time {
    color: var(--grey-light);
  }
}

.post .pagination {
  margin-block-start: var(--spacing-block-double);
}

.tags a {
  margin-inline-end: .5rem;
}

pre[class*="language-"] {
  width: calc(100vw - 2 * var(--spacing-inline));
  max-width: 80ch;
}

pre[class*="language-"]:has( + p), pre[class*="language-"]:has( + h2), pre[class*="language-"]:has( + h3), pre[class*="language-"]:has( + ol) {
  margin-block-end: 1.3rem;
  display: block;
}

.all-tags {
  columns: auto 12rem;
}

.all-tags li {
  break-inside: avoid;
}

.blog-posts--all {
  columns: auto 30rem;
}

.blog-posts--all li {
  break-inside: avoid;
}

.home-link {
  margin-inline-start: calc(var(--spacing-inline)  - var(--spacing-block-half));
}

.home-link a {
  padding: var(--spacing-block-double) var(--spacing-block-half);
  font-family: var(--accent);
  font-weight: var(--bold);
  font-size: var(--font-size-body-large);
  border-block-end: .5vh solid #0000;
  text-decoration: none;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: inline-block;
}

.home-link a:hover, .home-link a:focus {
  color: var(--background);
  background-color: var(--foreground);
  border-color: var(--accent-color);
  outline: none;
}

.footer {
  padding: 100px var(--spacing-inline) 50vw var(--spacing-inline);
  z-index: 2;
  color: var(--foreground);
  font-size: var(--font-size-body-large);
  margin: 0;
  position: relative;
}

.footer * {
  z-index: 1;
  position: relative;
}

@media (width >= 500px) {
  .footer {
    padding-block-end: 30vw;
  }
}

@media (width >= 800px) {
  .footer {
    grid-template-columns: 2fr 1fr;
    padding-block-end: 30vw;
    display: grid;
  }
}

@media (width >= 1200px) {
  .footer {
    padding-block-end: 20vw;
  }
}

.footer .footer__greeting {
  max-width: 60ch;
}

.footer .footer__meta {
  padding-block-start: 5vw;
}

@media (width >= 800px) {
  .footer .footer__meta {
    text-align: right;
    padding-block-start: 0;
  }
}

.footer .footer__nav {
  list-style: none;
}

.footer .footer__nav li {
  margin: 0;
}

.footer-backdrop {
  z-index: -1;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-backdrop:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-color-scheme: dark) {
  .footer-backdrop:before {
    backdrop-filter: invert();
    z-index: 2;
  }
}

.footer-backdrop img {
  object-fit: cover;
  mix-blend-mode: multiply;
  opacity: .98;
  width: 100%;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .footer-backdrop img {
    mix-blend-mode: none;
    opacity: .93;
  }
}

.top-cap {
  aspect-ratio: 264 / 189;
  z-index: -1;
  width: 40vw;
  position: absolute;
  top: 0;
  right: 0;
}

.top-cap:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-color-scheme: dark) {
  .top-cap:before {
    backdrop-filter: invert();
    z-index: 2;
  }
}

.top-cap img {
  object-fit: cover;
  mix-blend-mode: multiply;
  opacity: .98;
  width: 100%;
  height: 100%;
  display: block;
}

body.all .top-cap img {
  object-position: 110% -5vh;
}

@media (prefers-color-scheme: dark) {
  .top-cap img {
    mix-blend-mode: auto;
    opacity: .93;
  }
}

.post-summary {
  margin: 0 0 var(--spacing-block) 0;
  list-style-type: none;
}

.post-summary__time {
  color: var(--grey-medium);
  margin-block-end: var(--spacing-block-quarter);
  font-size: .73rem;
  display: block;
}

@media (prefers-color-scheme: dark) {
  .post-summary__time {
    color: var(--grey-light);
  }
}

.post-summary__title {
  margin-block-end: var(--spacing-block-third);
  font-size: 1.333rem;
}
/*# sourceMappingURL=styles.css.map */
