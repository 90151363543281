.top-cap {
  position: absolute;
  top: 0;
  right: 0;
  width: 40vw;
  // wtf - this fixes safari in dark mode
  aspect-ratio: 264 / 189;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    @media (prefers-color-scheme: dark) {
      backdrop-filter: invert(100%);
      z-index: 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;

    body.all & {
      object-position: 110% -5vh;
    }

    mix-blend-mode: multiply;
    opacity: 0.98;

    @media (prefers-color-scheme: dark) {
      mix-blend-mode: auto;
      opacity: 0.93;
    }
  }
}
