@use './includes/includes' as *;

.post-summary {
  list-style-type: none;
  margin: 0 0 var(--spacing-block) 0;
}

.post-summary__time {
  @include time-stamp();
}

.post-summary__title {
  font-size: 1.333rem;
  margin-block-end: var(--spacing-block-third);
}
