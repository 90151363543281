:root {
  --black: rgb(21 21 21);
  --almost-black: rgb(30, 30, 30);
  --grey-dark: rgb(51 51 51);
  --grey-medium: rgb(102 102 102);
  --grey-light: rgb(204 204 204);
  --white: rgb(250 250 250);
  --orange: rgb(253 139 38);
  --orange-tan: rgb(206, 145, 120);

  --accent: 'Source Serif 4', Georgia, serif;
  --system: -apple-system, BlinkMacSystemFont, sans-serif;

  --font-family-root: var(--system);

  --font-size-root: clamp(15px, 2vw + 0.1em, 20px);
  --font-size-body-large: 1.33rem;

  --bold: 700;
  --line-height: 1.46;

  --spacing-block: clamp(25px, 3vh, 30px);
  --spacing-block-half: calc(var(--spacing-block) / 2);
  --spacing-block-third: calc(var(--spacing-block) / 3);
  --spacing-block-quarter: calc(var(--spacing-block) / 4);
  --spacing-block-double: calc(var(--spacing-block) * 2);
  --spacing-block-triple: calc(var(--spacing-block) * 3);

  --spacing-inline: clamp(20px, 4vw, 80px);

  --background: var(--white);
  --foreground: var(--black);
  --accent-color: var(--orange);
  --code-text: var(--orange-tan);
  --code-background: var(--almost-black);

  @media (prefers-color-scheme: dark) {
    --background: var(--black);
    --foreground: var(--white);
    --bold: 800;
  }
}
