@mixin fade-in($duration: 2s) {
  @media (prefers-reduced-motion: no-preference) {
    animation-name: fade-in;
    animation-duration: #{$duration};
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }

}

@mixin time-stamp() {
  display: block;
  margin-block-end: var(--spacing-block-quarter);
  font-size: .73rem;
  color: var(--grey-medium);

  @media (prefers-color-scheme: dark) {
    color: var(--grey-light);
  }
}

