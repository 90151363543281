.footer {
  position: relative;
  margin: 0;
  padding: 100px var(--spacing-inline) 50vw var(--spacing-inline);

  z-index: 2;
  color: var(--foreground);
  font-size: var(--font-size-body-large);

  * {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 500px) {
    padding-block-end: 30vw;
  }

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @media (min-width: 800px) {
    padding-block-end: 30vw;
  }

  @media (min-width: 1200px) {
    padding-block-end: 20vw;
  }

  .footer__greeting {
    max-width: 60ch;
  }

  .footer__meta {
    padding-block-start: 5vw;

    @media (min-width: 800px) {
      padding-block-start: 0;
      text-align: right;
    }
  }

  .footer__nav {
    list-style: none;

    li {
      margin: 0;
    }
  }
}

.footer-backdrop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (prefers-color-scheme: dark) {
      backdrop-filter: invert(100%);
      z-index: 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    opacity: 0.98;

    @media (prefers-color-scheme: dark) {
      mix-blend-mode: none;
      opacity: 0.93;
    }
  }
}
